<template>
  <div>
    <el-card class="box-card">
      <div class="header">
        <div class="headerL">
          <img :src="schoolDetail.logo" alt="" />
        </div>
        <div class="headerR">
          <div class="title">
            <div class="titleText">{{ schoolDetail.organizationName }}</div>
            <div class="titleIcon"><i class="el-icon-star-off"></i></div>
          </div>
          <div class="introduce">{{ schoolDetail.introduction }}</div>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="课程" name="first">
          <div class="list">
            <div v-for="item in courseList" :key="item.productId" class="listItem">
              <div class="picCover">
                <img :src="item.pic" alt="" />
                <div v-if="item.courseType == 0" class="liveStatus">
                  <img src="../../../assets/shop/lubo_icon.png" alt="" />
                </div>
                <div v-else class="liveStatus">
                  <img src="../../../assets/shop/zhibo_icon.png" alt="" />
                </div>
              </div>
              <div class="courseText">
                <div class="courseName">{{ item.courseName }}</div>
                <div class="courseIntroduce">
                  {{ item.sectionCount }}节| {{ item.duration }}分钟 | 融e学
                </div>
                <div class="courseBuyDetail">
                  <div class="price">{{ item.price }}元</div>
                  <div>{{ item.studentCount }}人购买</div>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <el-pagination
              :current-page.sync="coursePager.current"
              :page-size="coursePager.size"
              layout="prev, pager,next,total,jumper"
              :total="coursePager.total"
              @size-change="courseHandleSizeChange"
              @current-change="courseHandleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="班级" name="second">
          <div class="list">
            <div v-for="item in classList" :key="item.productId" class="listItem">
              <div class="picCover">
                <img :src="item.pic" alt="" />
              </div>
              <div class="courseText">
                <div class="courseName">{{ item.className }}</div>
                <div class="courseBuyDetail">
                  <div class="price">{{ item.price }}元</div>
                  <div>{{ item.studentCount }}人购买</div>
                </div>
                <div class="courseCount">{{ item.courseCount }}门课程</div>
              </div>
            </div>
          </div>
          <div class="block">
            <el-pagination
              :current-page.sync="classPager.current"
              :page-size="classPager.size"
              layout="prev, pager,next,total,jumper"
              :total="classPager.total"
              @size-change="classHandleSizeChange"
              @current-change="classHandleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="讲师" name="third">
          <div class="list">
            <div v-for="item in teacherList" :key="item.productId" class="listItem">
              <div class="picCover">
                <img :src="item.teacherPic" alt="" />
              </div>
              <div class="courseText">
                <div class="courseBuyDetail">
                  <div class="price">{{ item.teacherName }}</div>
                  <div>{{ item.productCourseCount }}门课程 | {{ item.teacherFollowReal }}关注</div>
                </div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="courseCount" v-html="item.teacherDetails">
                  {{ item.teacherDetails }}
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <el-pagination
              :current-page.sync="teacherPager.current"
              :page-size="teacherPager.size"
              layout="prev, pager,next,total,jumper"
              :total="teacherPager.total"
              @size-change="teacherHandleSizeChange"
              @current-change="teacherHandleCurrentChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { schoolDetail, schoolClass, schoolCourse, schoolTeacher } from '@/api/teacher'
import to from 'await-to'
export default {
  data() {
    return {
      activeName: 'first',
      organizationId: this.$route.query.organizationId,
      schoolDetail: {},
      coursePager: {
        size: 10,
        current: 1,
        total: 0,
      },
      classPager: {
        size: 10,
        current: 1,
        total: 0,
      },
      teacherPager: {
        size: 10,
        current: 1,
        total: 0,
      },
      classList: [],
      courseList: [],
      teacherList: [],
    }
  },
  created() {
    this.schoolDetailData()
    this.schoolClassData()
    this.schoolCourseData()
    this.schoolTeacherData()
  },
  methods: {
    async schoolTeacherData() {
      const [res, err] = await to(
        schoolTeacher({ organizationId: this.organizationId, ...this.coursePager }),
      )
      if (err) return this.$message.warning(err.msg)
      this.teacherPager.total = res.data.total
      this.teacherList = res.data.list
    },
    async schoolCourseData() {
      const [res, err] = await to(
        schoolCourse({ organizationId: this.organizationId, ...this.coursePager }),
      )
      if (err) return this.$message.warning(err.msg)
      this.coursePager.total = res.data.total
      this.courseList = res.data.list
    },
    async schoolDetailData() {
      const [res, err] = await to(schoolDetail({ organizationId: this.organizationId }))
      if (err) return this.$message.warning(err.msg)
      this.schoolDetail = res.data
    },
    async schoolClassData() {
      const [res, err] = await to(
        schoolClass({ organizationId: this.organizationId, ...this.coursePager }),
      )
      if (err) return this.$message.warning(err.msg)
      this.classPager.total = res.data.total
      this.classList = res.data.list
    },
    handleClick() {
      //   console.log(tab, event)
    },
    //课程分页
    courseHandleSizeChange(size) {
      this.coursePager.current = 1
      this.coursePager.size = size
      this.schoolCourseData()
    },
    courseHandleCurrentChange(current) {
      this.coursePager.current = current
      this.schoolCourseData()
    },
    //班級分页
    classHandleSizeChange(size) {
      this.coursePager.current = 1
      this.coursePager.size = size
      this.schoolClassData()
    },
    classHandleCurrentChange(current) {
      this.coursePager.current = current
      this.schoolClassData()
    },
    //讲师分页
    teacherHandleSizeChange(size) {
      this.coursePager.current = 1
      this.coursePager.size = size
      this.schoolTeacherData()
    },
    teacherHandleCurrentChange(current) {
      this.coursePager.current = current
      this.schoolTeacherData()
    },
  },
}
</script>

<style scoped lang="scss">
.box-card {
  margin-bottom: 30px !important;
  .header {
    display: flex;
    .headerL {
      width: 200px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .headerR {
      margin-left: 30px;
      width: 500px;
      .title {
        font-size: 18px;
        color: #333;
        display: flex;
        justify-content: space-between;
        .titleIcon {
          font-size: 20px;
        }
      }
      .introduce {
        margin-top: 20px;
        color: #606266;
        font-size: 14px;
      }
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  .listItem {
    float: left;
    position: relative;
    margin-right: 23px;
    margin-bottom: 24px;
    border-radius: 4px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.06);
    transition: all 0.3s;
    &:hover {
      transform: translateY(-5px);
    }
    .picCover {
      width: 282px;
      height: 145px;
      img {
        width: 100%;
        height: 100%;
      }
      .liveStatus {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 56px;
        height: 22px;
      }
    }
    .courseText {
      padding: 14px 10px;
      .courseName {
        font-size: 14px;
        color: #3e3e3e;
        line-height: 30px;
      }
      .courseIntroduce {
        font-size: 12px;
        color: #666;
        line-height: 20px;
      }
      .courseBuyDetail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #666;
        .price {
          font-size: 24px;
          color: #ff583e;
        }
      }
      .courseCount {
        line-height: 30px;
        font-size: 12px;
        color: #666;
      }
    }
  }
}
.block {
  margin-top: 50px;
  margin-bottom: 30px;
  height: 50px;
  display: flex;
  justify-content: center;
}
</style>
